import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { NodeType } from '../../../typings/organization/org-manage';
let Avatar = class Avatar extends Vue {
    constructor() {
        super(...arguments);
        this.NodeType = NodeType;
    }
};
__decorate([
    Prop()
], Avatar.prototype, "nodeType", void 0);
__decorate([
    Prop()
], Avatar.prototype, "imgUrl", void 0);
Avatar = __decorate([
    Component({
        name: 'Avatar',
    })
], Avatar);
export default Avatar;
